<template>
    <div class="footer">
        <div class="footer_nav">
            <tr class="nav_list">
                <th>About Honfu</th>
                <td><a href="/about">About us</a></td>
                 <td><a href="/field">Field</a></td>
                <td><a href="/products">Products</a></td>
                <td><a href="/workmanship">Workmanship</a></td>
                <td><a href="/news">News</a></td>
                <td><a href="/contact">Contact us</a></td>
            </tr>
            <tr class="nav_list">
                <th>Field</th>
                <td v-for="(item,index) in appList" :key="index">
                    <a v-if="index<8" :href="`/field?id=${item.id}&type=${index+1}`">{{item.name}}</a>
                </td>
                <!-- <td><a href="/field?type=1&id=17">Semiconductor</a></td>
                <td><a href="/field?type=2&id=18">Electronic products</a></td>
                <td><a href="/field?type=3&id=19">Automobile making</a></td>
                <td><a href="/field?type=4&id=20">Household appliances</a></td>
                <td><a href="/field?type=5&id=21">Instrument Equipment</a></td>
                <td><a href="/field?type=6&id=22">Medical cosmetology</a></td>
                <td><a href="/field?type=7&id=23">Aerospace and military industry</a></td>
                <td><a href="/field?type=8&id=24">New energy</a></td> -->
            </tr>
            <tr class="nav_list">
                <th>Products</th>
                <td v-for="(item,index) in productsList" :key="index">
                    <a v-if="index<8" :href="`/products?pid=${item.id}&type=${index+1}`">{{item.name}}</a>
                </td>
                <!-- <td><a href="/products?pid=5&type=1">Lead frame</a></td>
                <td><a href="/products?pid=6&type=2">Folding screen</a></td>
                <td><a href="/products?pid=7&type=3">Vapor Chambers</a></td>
                <td><a href="/products?pid=8&type=4">Hydrogen fuel cell</a></td>
                <td><a href="/products?pid=9&type=5">Automotive interior</a></td>
                <td><a href="/products?pid=10&type=6">Television speaker mesh</a></td>
                <td><a href="/products?pid=11&type=7">Razor blade</a></td>
                <td><a href="/products?pid=12&type=8">Hair dryer mesh</a></td>
                <td><a href="/products?pid=13&type=9">Vacuum cleaner mesh</a></td>
                <td><a href="/products?pid=14&type=10">Encoder Disc</a></td> -->
            </tr>
            <tr class="nav_list">
                <th>Workmanship</th>
                <td><a href="/workmanship">Processing advantage</a></td>
                <td><a href="/workmanship">Technological process</a></td>
                <td><a href="/workmanship">Company strength</a></td>
                <td><a href="/workmanship">Certification qualification</a></td>
            </tr>
            <tr class="nav_list">
                <th>News</th>
                <td><a href="/newsbranch?type=25">Company dynamics</a></td>
                <td><a href="/newsbranch?type=26">Industry dynamics</a></td>
                <!-- <td><a href="">Technology application</a></td>
                <td><a href="">common problem</a></td> -->
            </tr>
        </div>
        <div class="footer_tu">
            <div class="liuyan" @click="goContact">
                <img src="../assets/images/footer/liuyan.png" width="24px" height="24px" alt="">
                <div style="margin-left:12px;font-size: 16px;">Online Message</div>
            </div>
            <div class="dianhua">
                <img src="../assets/images/footer/dianhua.png" width="24px" height="24px" alt="">
                <div style="margin-left:12px;font-size: 16px;">
                    {{contact1}}
                    <br />
                    {{contact2}}
                </div>
            </div>
            <div class="youxiang">
                <img src="../assets/images/footer/youxiang.png" width="24px" height="24px" alt="">
                <div style="margin-left:10px;font-size: 16px;">{{mailbox}}</div>
            </div>
            <div class="tuliu">
                <img src="../assets/images/footer/tuite.png" @click="golinks(2)" width="24px" height="24px" alt="">
                <img src="../assets/images/footer/linyin.png" @click="golinks(3)" width="24px" height="24px" alt="">
                <img src="../assets/images/footer/facebook.png" @click="golinks(4)" width="24px" height="24px" alt="">
                <img src="../assets/images/footer/youtube.png" @click="golinks(5)" width="24px" height="24px" alt="">
            </div>
        </div>
    </div>
</template>
<script>
import {getFooterInfo,getFooterLinks,getClassIfication} from '../api/index'
export default {
    data () {
        return {
            contact1: '',
            contact2: '',
            mailbox: '',
            links:[],
            productsList: [],
            appList: []
        }
    },
    created(){
        // 获取底部信息
        getFooterInfo().then(res=>{
            this.mailbox = res.data.site_email
            let ren = res.data.site_tel.split(',')
            this.contact1 =  ren[0]
            this.contact2 = ren[1]
        })
        getFooterLinks().then(res=>{
            this.links = res.data
        })
         // 获取产品分类列表
        getClassIfication({ id: 2 }).then(res => {
            res.data.categories.sort((a, b) => a.list_order - b.list_order)
            this.productsList = res.data.categories.filter(item=>{
                return item.status == 1
            })
        })
        // 获取应用领域信息
        getClassIfication({ id: 4 }).then(res => {
            res.data.categories.sort((a, b) => a.list_order - b.list_order)
            this.appList = res.data.categories.filter(item=>{
                return item.status == 1
            })
        })
    },
    methods:{
        // 跳转联系我们
        goContact(){
            this.$router.push({name:'contact'})
        },
        golinks(val){
            this.links.forEach(item=>{
                if(item.id == val){
                    window.open(`${item.url}`,'_blank')
                }
            })
        }
    }
}
</script>
<style scoped lang='less'>
.footer {
    width: 100%;
    height: 570px;
    background: #1f2329;
    box-sizing: border-box;
    padding: 48px 160px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    .footer_nav {
        display: flex;
        .nav_list {
            display: flex;
            flex-direction: column;
            margin-right: 40px;
            th {
                text-align: left;
                height: 29px;
                font-size: 20px;
                font-weight: 500;
                color: #ffffff;
                line-height: 29px;
            }
            td {
                margin-top: 20px;
                width: 228px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                a {
                    height: 24px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #ccc;
                    line-height: 24px;
                    
                }
                a:hover {
                    color: #fff;
                }
            }
        }
    }
    .footer_tu {
        width: 224px;
        .liuyan {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 214px;
            height: 40px;
            background: rgba(255, 255, 255, 0.3);
            margin-bottom: 32px;
            cursor: pointer;
        }
        .dianhua {
            display: flex;
            margin-bottom: 32px;
        }
        .youxiang {
            display: flex;
            margin-bottom: 32px;
        }
        .tuliu {
            display: flex;
            justify-content: space-between;
            align-items: center;
            img{
                cursor: pointer;
            }
        }
    }
}
</style>