import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/home.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about/about.vue'),
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/products/products.vue'),
  },
  {
    path: '/workmanship',
    name: 'workmanship',
    component: () => import('@/views/workmanship/workmanship.vue'),
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/news/news.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/contact/contact.vue'),
  },
  {
    path: '/newsbranch',
    name: 'newsbranch',
    component: () => import('@/views/news/newsbranch.vue'),
  },
  {
    path: '/newdetails',
    name: 'newdetails',
    component: () => import('@/views/news/newDetails.vue'),
  },
  {
    path: '/productsdetail',
    name: 'productsdetail',
    component: () => import('@/views/products/productsDetail.vue'),
  },
  {
    path: '/field',
    name: 'field',
    component: () => import('@/views/field/field.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
