<template>
    <div id="app">
        <Header class="app_header"></Header>
        <router-view />
        <Footer class="app_footer"></Footer>
    </div>
</template>
<script>
import Header from './components/header.vue'
import Footer from './components/footer.vue'
export default {
    components: {
        Header,
        Footer
    }
}
</script>

<style lang="less">
#app{
    width: 1920px;
    margin: 0 auto;
    position: relative;
    .app_header{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
    }
}
</style>
